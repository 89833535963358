<template>
	<footer class="w-full bg-cream pb-20 lg:pb-32" v-cloak>
		<transition>
			<div
				v-if="
					scrollHeight >= 900 && this.$route.name.indexOf('menus') < 0
				"
				class="rounded-full bg-brand w-12 h-12 cursor-pointer fixed bottom-24 right-5 z-50 drop-shadow-lg"
				@click="scrollToTop"
			>
				<img
					loading="lazy"
					alt="Image"
					src="@/assets/back-to-top.svg"
				/>
			</div>
		</transition>
		<div
			class="flex justify-start lg:justify-between items-start mx-5 lg:mx-28 2xl:mx-64 pt-10 lg:pt-20 flex-col lg:flex-row border-t border-brand"
		>
			<div class="w-full lg:w-auto flex justify-between items-center">
				<div class="lg:block">
					<router-link to="/">
						<img
							loading="lazy"
							alt="Image"
							src="@/assets/cote_diamond_logo_navy_frame.svg"
							class="max-w-full"
						/>
					</router-link>
				</div>
				<p class="lg:hidden text-xs text-left mr-2 tracking-normal">
					&#169; {{ thisYear }} Côte Restaurants Group Ltd.
				</p>
			</div>
			<div
				class="w-full lg:w-auto flex flex-wrap lg:flex-nowrap justify-between lg:justify-start mt-10 lg:mt-0"
			>
				<nav
					v-for="footerMenu in footerMenus"
					:key="footerMenu.menu_title"
					class="p-0 text-left mr-12 xl:mr-28 mt-8 lg:mt-0"
					:style="{ order: footerMenu.menu_order }"
				>
					<h5 class="mb-4 mt-0">{{ footerMenu.menu_title }}</h5>
					<div class="flex flex-col lg:flex-row">
						<ul
							v-for="(
								column, columnKey
							) in footerMenu.menu_item_columns"
							:key="columnKey"
							:class="
								columnKey === 'columnOne'
									? 'mr-12 xl:mr-28'
									: ''
							"
						>
							<li v-for="item in column" :key="item.ID">
								<router-link
									v-if="!isExternalLink(item.url)"
									class="text-brand text-sm font-parisine block font-normal hover:text-grey"
									:to="generateUrl(item.url)"
									>{{ item.title }}</router-link
								>
								<a
									v-if="isExternalLink(item.url)"
									class="text-brand text-sm font-parisine block font-normal hover:text-grey"
									:href="generateUrl(item.url)"
									target="_blank"
									rel="noopener noreferrer"
									>{{ item.title }}</a
								>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<div
				class="lg:hidden w-full lg:w-auto flex justify-between my-10 lg:mt-0"
			>
				<div>
					<h5 class="mb-4 mt-0 text-left">Social</h5>
					<div class="flex justify-between items-center w-32">
						<a
							href="https://instagram.com/coteuk"
							target="_blank"
							rel="noopener noreferrer"
							class="mr-1.5"
							><img
								loading="lazy"
								alt="Image"
								src="@/assets/instagram.png"
						/></a>
						<a
							href="https://www.facebook.com/coterestaurant/"
							target="_blank"
							rel="noopener noreferrer"
							><img
								loading="lazy"
								alt="Image"
								src="@/assets/facebook.png"
						/></a>
						<a
							href="https://www.linkedin.com/company/c%C3%B4te-brasserie"
							target="_blank"
							rel="noopener noreferrer"
							><img
								loading="lazy"
								alt="Image"
								src="@/assets/linkedin.png"
						/></a>
					</div>
				</div>
			</div>
			<div class="hidden lg:block">
				<h5 class="text-left mb-4 mt-0">Social</h5>
				<div class="flex justify-between items-center lg:w-28 xl:w-32">
					<a
						href="https://instagram.com/coteuk"
						target="_blank"
						rel="noopener noreferrer"
						class="mr-1.5"
						><img
							loading="lazy"
							alt="Image"
							src="@/assets/instagram.png"
					/></a>
					<a
						href="https://www.facebook.com/coterestaurant/"
						target="_blank"
						rel="noopener noreferrer"
						><img
							loading="lazy"
							alt="Image"
							src="@/assets/facebook.png"
					/></a>
					<a
						href="https://www.linkedin.com/company/c%C3%B4te-brasserie"
						target="_blank"
						rel="noopener noreferrer"
						><img
							loading="lazy"
							alt="Image"
							src="@/assets/linkedin.png"
					/></a>
				</div>
			</div>
		</div>
		<p
			class="hidden lg:block text-xs text-left ml-5 lg:ml-28 xl:ml-40 2xl:ml-64 mt-20 lg:mt-32 tracking-normal"
		>
			&#169; {{ thisYear }} Côte Restaurants Group Ltd.
		</p>
	</footer>
</template>
<script>
export default {
	name: 'FooterLayout',
	data() {
		return {
			scrollHeight: 0,
		};
	},
	computed: {
		thisYear() {
			return new Date().getFullYear();
		},
		navLinksOne() {
			return this.$store.getters.getMenuByName('footerLinksOne');
		},
		navLinksTwo() {
			return this.$store.getters.getMenuByName('footerLinksTwo');
		},
		navPolicies() {
			return this.$store.getters.getMenuByName('footerPolicies');
		},
		navQuestions() {
			return this.$store.getters.getMenuByName('footerQuestions');
		},
		footerMenus() {
			return this.$store.getters.getMenuByName('footerMenus');
		},
	},
	methods: {
		getScrollHeight() {
			this.scrollHeight = window.scrollY;
		},
		scrollToTop() {
			window.scrollBy({
				top: -this.scrollHeight,
				behavior: 'smooth',
			});
		},
	},
	mounted() {
		document.addEventListener('scroll', this.getScrollHeight);
	},
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.25s ease;
}
.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.c-diamond {
	margin-left: 30px;
	width: 100px;
	height: 100px;
	@media (min-width: 1024px) {
		width: 120px;
		height: 120px;
	}
	background: transparent;
	border: 1px solid #0a2036;
	transform: rotateX(45deg) rotateZ(45deg);
}
</style>
