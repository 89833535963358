<template>
	<transition>
		<div v-if="show" id="pagePlaceholder" :class="classes">
			<p class="loading-spinner__text">{{ text }}</p>
			<div class="loading-spinner"></div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'Placeholder',
	props: {
		show: {
			type: Boolean,
			default: null,
		},
		text: {
			type: String,
			default: '',
		},
		classes: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
#pagePlaceholder {
	height: 100vh;
}

@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}
.loading-spinner {
	// The height here is just for demo purposes
	height: 100vh;
	opacity: 1;
	position: relative;
	transition: opacity linear 0.1s;
	&::before {
		animation: 2s linear infinite spinner;
		border: solid 3px #eee;
		border-bottom-color: #f1b434;
		border-radius: 50%;
		content: '';
		height: 40px;
		left: 50%;
		opacity: inherit;
		position: absolute;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		transform-origin: center;
		width: 40px;
		will-change: transform;
	}

	&__text {
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		top: 50vh;
	}
}
</style>
