<template>
	<header
		id="header"
		class="w-full bg-brand flex justify-between items-center px-5 lg:px-10 py-6 sticky top-0 z-60 max-h-[64px] xl:max-h-[80px]"
		v-cloak
	>
		<router-link to="/" @click="hideMenu"
			><img
				loading="lazy"
				alt="Image"
				src="@/assets/cote-logo.svg"
				class="w-32"
		/></router-link>
		<nav class="p-0">
			<router-link
				class="text-cream hover:text-mustard nav-text uppercase mx-4 hidden xl:inline"
				to="/book-a-table"
				active-class="text-mustard"
				@click="hideMenu"
				>Book A Table</router-link
			>
			<div class="hidden xl:inline" v-for="item in nav" :key="item.ID">
				<router-link
					v-if="!isExternalLink(item.url)"
					class="text-cream hover:text-mustard nav-text uppercase mx-4"
					:to="generateUrl(item.url)"
					active-class="text-mustard"
					@click="hideMenu"
					>{{ item.title }}</router-link
				>
				<a
					v-if="isExternalLink(item.url)"
					class="text-cream hover:text-mustard nav-text uppercase mx-4"
					:href="generateUrl(item.url)"
					target="_blank"
					rel="noopener noreferrer"
					@click="hideMenu"
					>{{ item.title }}</a
				>
			</div>
			<div
				id="header-chat-icon-container"
				class="ml-8 w-10 inline-block cursor-pointer chat-closed"
				:class="{ invisible: wpOptions.options_chat_box != 1 }"
			>
				<inline-svg
					:src="require('@/assets/icons-chat.svg')"
					width="40"
					height="40"
					id="header-chat-icon-open"
					@click="requestLiveChatPopup"
					@mouseover="hover.chat = true"
					@mouseout="hover.chat = false"
					:class="{ 'chat-icon--yellow': hover.chat }"
				/>
				<img
					loading="lazy"
					alt="Image"
					src="@/assets/close-mustard.png"
					id="header-chat-icon-close"
					@click="closeLiveChatPopup"
				/>
			</div>
			<inline-svg
				:src="require('@/assets/icons-nav-menu.svg')"
				width="40"
				height="40"
				id="menu-icon-bars"
				v-show="!menuVisible"
				@mouseover="hover.menu = true"
				@mouseout="hover.menu = false"
				@click="showMenu"
				:class="{ 'menu-icon--yellow': hover.menu }"
				class="ml-6 w-10 inline cursor-pointer"
			/>
			<x-icon
				v-show="menuVisible"
				class="ml-6 w-10 text-mustard inline cursor-pointer"
				@mouseover="hover.menu = true"
				@mouseout="hover.menu = false"
				@click="hideMenu"
				:class="{ 'menu-icon--yellow': hover.menu }"
			></x-icon>
		</nav>
		<menu-layout
			:visible="menuVisible"
			@menu-closed="this.hideMenu"
			@open-bookings="$emit('openBookings')"
		></menu-layout>
	</header>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline';
import InlineSvg from 'vue-inline-svg';
import MenuLayout from '@/components/layouts/MenuLayout.vue';

export default {
	name: 'HeaderLayout',
	components: {
		XIcon,
		MenuLayout,
		InlineSvg,
	},
	data() {
		return {
			hover: {
				menu: false,
				chat: false,
			},
			menuVisible: false,
		};
	},
	computed: {
		nav() {
			return this.$store.getters.getMenuByName('headerMain');
		},
		wpOptions() {
			return this.$store.getters.getWpOptions();
		},
	},
	methods: {
		showMenu() {
			this.menuVisible = true;
			this.$emit('menuOpen', true);
			this.closeLiveChatPopup();
		},

		hideMenu() {
			this.menuVisible = false;
			this.$emit('menuOpen', false);
		},
	},
};
</script>

<style lang="scss">
.nav-text {
	padding-bottom: 2px;

	&:hover {
		border-bottom: 1px solid rgb(241 180 52);
	}
}

#header-chat-icon-container > svg > path {
	transition: 0.3s;
}
#menu-icon-bars > path {
	transition: 0.3s;
}

.chat-icon--yellow {
	position: relative;

	> path {
		fill: #d6b045 !important;
	}
}

.menu-icon--yellow {
	position: relative;

	/* Polyfil to prevent priority changes in class orders for production */
	&.absolute {
		position: absolute;
	}

	> path {
		fill: #d6b045 !important;
	}
}
</style>
