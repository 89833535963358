<template>
  <div>
    <iframe
      title="Steak Soiree Night"
      :src="iFrameURL"
      class="iframe-st"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iFrameURL: `${process.env.VUE_APP_DOMAIN  }/embeded-data/steak-soiree`,
    };
  },
  beforeUnmount() {
    document.body.style.overflowY = 'auto';
  },
  mounted() {
    document.body.style.overflowY = 'hidden';
  },
};
</script>

<style>
.iframe-st {
  width: 100%;
  height: 100vh;
  padding-bottom: 50px;
}
@media screen and (max-width: 768px){
  .iframe-st{
    border-bottom: 102px solid #0a2036;
  }
}
</style>
