export const EVENT_COST = Object.freeze({
	FREE: 'Free',
	PAID: 'Paid',
});
export const NATIONAL = 'National';

const BIRTH_DAY = 'Birthday';
const ANNIVERSARY = 'Anniversary';
const BUSINESS_MEAL = 'Business Meal';
const FAMILY_MEAL = 'Family Meal';
const DATE_NIGHT = 'Date Night';

export const SPECIAL_OCCASIONS = Object.freeze({
	BIRTH_DAY,
	ANNIVERSARY,
	BUSINESS_MEAL,
	FAMILY_MEAL,
	DATE_NIGHT,
});
