<template>
	<transition>
		<div
			v-show="visible"
			class="fixed left-0 top-[64px] xl:top-[0px] lg:top-0 w-full h-full bg-brand p-6 lg:pt-20 lg:px-16 xl:p-40 z-70 overflow-y-auto"
		>
			<x-icon
				class="hidden lg:block w-10 cursor-pointer text-mustard absolute top-6 right-10"
				@mouseover="hover.menu = true"
				@mouseout="hover.menu = false"
				@click="$emit('menuClosed')"
				:class="{ 'menu-icon--yellow': hover.menu }"
			></x-icon>
			<div class="lg:flex">
				<div
					class="lg:w-5/12 lg:mt-5 xl:mt-8 flex flex-col justify-center"
				>
					<div class="hidden lg:block relative w-[230px]">
						<router-link to="/" @click="this.$emit('menuClosed')">
							<img
								loading="lazy"
								alt="Image"
								src="@/assets/cote_diamond_logo_gold_frame.svg"
								class=""
							/>
						</router-link>
					</div>
					<nav class="block lg:hidden p-0 pt-0">
						<router-link
							to="/book-a-table"
							class="text-cream hover:text-mustard uppercase font-copperplate tracking-widest block text-left text-3xl px-0 py-3 text-center lg:text-left cursor-pointer"
							@click="this.$emit('menuClosed')"
							>Book A Table</router-link
						>
						<div v-for="item in nav" :key="item.ID">
							<router-link
								v-if="!isExternalLink(item.url)"
								class="text-cream hover:text-mustard uppercase font-copperplate tracking-widest block text-left text-3xl px-0 py-3 text-center lg:text-left"
								:to="generateUrl(item.url)"
								active-class="text-mustard underline"
								@click="this.$emit('menuClosed')"
								>{{ item.title }}</router-link
							>
							<a
								v-if="isExternalLink(item.url)"
								class="text-cream hover:text-mustard uppercase font-copperplate tracking-widest block text-left text-3xl px-0 py-3 text-center lg:text-left"
								:href="item.url"
								target="_blank"
								rel="noopener noreferrer"
								@click="this.$emit('menuClosed')"
								>{{ item.title }}</a
							>
						</div>
					</nav>
					<div
						class="flex justify-between lg:justify-start mt-4 mb-4 lg:mt-10 lg:mb-6"
					>
						<nav class="py-0 px-1">
							<div v-for="item in subNavOne" :key="item.ID">
								<router-link
									v-if="!isExternalLink(item.url)"
									class="menu-text-small block text-center lg:text-left px-4 lg:pl-0 py-2 lg:py-3 lg:mr-3"
									:to="generateUrl(item.url)"
									active-class="text-mustard"
									@click="this.$emit('menuClosed')"
									>{{ item.title }}</router-link
								>
								<a
									v-if="isExternalLink(item.url)"
									class="menu-text-small block text-center lg:text-left px-4 py-2 lg:py-3 lg:pl-0 lg:mr-3"
									:href="generateUrl(item.url)"
									target="_blank"
									rel="noopener noreferrer"
									@click="this.$emit('menuClosed')"
									>{{ item.title }}</a
								>
							</div>
						</nav>
						<nav class="py-0 px-1">
							<div v-for="item in subNavTwo" :key="item.ID">
								<router-link
									v-if="!isExternalLink(item.url)"
									class="menu-text-small block text-center lg:text-left px-4 py-2 lg:py-3 lg:mr-3"
									:to="generateUrl(item.url)"
									active-class="text-mustard"
									@click="this.$emit('menuClosed')"
									>{{ item.title }}</router-link
								>
								<a
									v-if="isExternalLink(item.url)"
									class="menu-text-small block text-center lg:text-left px-4 py-2 lg:py-3 lg:mr-3"
									:href="generateUrl(item.url)"
									target="_blank"
									rel="noopener noreferrer"
									@click="this.$emit('menuClosed')"
									>{{ item.title }}</a
								>
							</div>
						</nav>
					</div>
					<button
						type="button"
						@click="triggerLiveChat"
						class="button-standard font-copperplate bg-mustard py-4 px-6 lg:py-4 lg:px-10 mt-4 w-full lg:w-52 uppercase font-bold lg:font-extrabold text-sm m-0"
						:class="
							wpOptions.options_chat_box == 1
								? 'inline-block lg:block'
								: 'hidden'
						"
					>
						Live chat
					</button>
					<div
						class="flex justify-center items-center mt-8 lg:hidden"
					>
						<a href="https://instagram.com/coteuk">
							<img
								alt="Image"
								loading="lazy"
								src="@/assets/instagram-light.png"
								class="max-w-full"
							/>
						</a>
						<a href="https://www.facebook.com/coterestaurant/">
							<img
								alt="Image"
								loading="lazy"
								src="@/assets/facebook-light.png"
								class="max-w-full px-1"
							/>
						</a>
						<a
							href="https://www.linkedin.com/company/c%C3%B4te-brasserie"
						>
							<img
								alt="Image"
								loading="lazy"
								src="@/assets/linkedin-light.png"
								class="max-w-full"
							/>
						</a>
					</div>
				</div>
				<div class="lg:w-7/12">
					<nav class="hidden lg:block p-0 pt-0">
						<router-link
							to="/book-a-table"
							class="c-main-menu menu-text-large relative hover:text-mustard block mt-0 px-0 py-6 pt-0 pl-20 text-center lg:text-left cursor-pointer"
							@click="this.$emit('menuClosed')"
							>Book A Table</router-link
						>
						<div v-for="item in nav" :key="item.ID">
							<router-link
								v-if="!isExternalLink(item.url)"
								class="c-main-menu menu-text-large relative hover:text-mustard block px-0 py-6 pl-20 text-center lg:text-left"
								:to="generateUrl(item.url)"
								active-class="text-mustard"
								@click="this.$emit('menuClosed')"
								>{{ item.title }}</router-link
							>
							<a
								v-if="isExternalLink(item.url)"
								class="c-main-menu menu-text-large relative hover:text-mustard block px-0 py-6 pl-20 text-center lg:text-left"
								:href="generateUrl(item.url)"
								target="_blank"
								rel="noopener noreferrer"
								@click="this.$emit('menuClosed')"
								>{{ item.title }}</a
							>
						</div>
					</nav>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline';

export default {
	name: 'MenuLayout',
	components: {
		XIcon,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			hover: {
				menu: false,
			},
		};
	},
	computed: {
		nav() {
			return this.$store.getters.getMenuByName('headerMain');
		},
		subNavOne() {
			return this.$store.getters.getMenuByName('headerSubOne');
		},
		subNavTwo() {
			return this.$store.getters.getMenuByName('headerSubTwo');
		},
		wpOptions() {
			return this.$store.getters.getWpOptions();
		},
	},
	methods: {
		triggerLiveChat() {
			this.$emit('menuClosed');
			this.requestLiveChatPopup();
		},
	},
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.25s ease;
}
.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.c-main-menu {
	&:before {
		content: url('@/assets/active_menu.png');
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
	}
	&:hover:before {
		opacity: 1;
	}
}

.menu-text-large,
.menu-text-small {
	&:hover {
		border-bottom: 1px solid rgb(241 180 52);
	}
}
</style>
