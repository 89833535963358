import { createApp } from 'vue';
import Datepicker from 'vue3-datepicker';
import globalHelpers from '@/plugins/globalHelpers';
import store from '@/store/store';
import VueLazyload from 'vue-lazyload';
import VueCookies from 'vue-cookies';
import VueGtag from 'vue-gtag';
import './assets/tailwind.css';
import './assets/animations.css';

// Layout blocks
import AnchorNavigationBlock from '@/components/blocks/AnchorNavigationBlock.vue';
import BrasserieListBlock from '@/components/blocks/BrasserieListBlock.vue';
import BrasserieDropdownBlock from '@/components/blocks/BrasserieDropdownBlock.vue';
import SocialCompetitionBlock from '@/components/blocks/SocialCompetitionBlock.vue';
import CompetitionBlock from '@/components/blocks/CompetitionBlock.vue';
import CallToActionBlock from '@/components/blocks/CallToActionBlock.vue';
import CelebrationBlock from '@/components/blocks/CelebrationBlock.vue';
import ContactFormBlock from '@/components/blocks/ContactFormBlock.vue';
import ExponeaAttributeBlock from '@/components/blocks/ExponeaAttributeBlock.vue';
import FaqsBlock from '@/components/blocks/FaqsBlock.vue';
import FreeTextBlock from '@/components/blocks/FreeTextBlock.vue';
import HeaderBlock from '@/components/blocks/HeaderBlock.vue';
import ImageRowBlock from '@/components/blocks/ImageRowBlock.vue';
import InstagramFeedBlock from '@/components/blocks/InstagramFeedBlock.vue';
import LargeImageAndTextBlock from '@/components/blocks/LargeImageAndTextBlock.vue';
import LargeTextBlock from '@/components/blocks/LargeTextBlock.vue';
import MealsBlock from '@/components/blocks/MealsBlock.vue';
import MenuSwapsBlock from '@/components/blocks/MenuSwapsBlock.vue';
import MoodBoardBlock from '@/components/blocks/MoodBoardBlock.vue';
import NewsletterBlock from '@/components/blocks/NewsletterBlock.vue';
import NewsletterFormBlock from '@/components/blocks/NewsletterFormBlock.vue';
import ParagraphGridBlock from '@/components/blocks/ParagraphGridBlock.vue';
import ParallaxImageGridBlock from '@/components/blocks/ParallaxImageGridBlock.vue';
import TwoColumnCenteredBlock from '@/components/blocks/TwoColumnCenteredBlock.vue';
import TwoColumnFourImagesBlock from '@/components/blocks/TwoColumnFourImagesBlock.vue';
import TwoColumnTwoImagesBlock from '@/components/blocks/TwoColumnTwoImagesBlock.vue';
import TwoColumnVideoBlock from '@/components/blocks/TwoColumnVideoBlock.vue';
import UnsubscribeBlock from '@/components/blocks/UnsubscribeBlock.vue';

import App from './App.vue';
import router from './router';

createApp(App)
	.use(router)
	.use(VueCookies)
	.use(store)
	.use(VueLazyload, {
		lazyComponent: true,
	})
	.use(
		VueGtag,
		{
			config: {
				id: process.env.VUE_APP_GOOGLE_ANALYTICS,
			},
			pageTrackerEnabled: false,
			enabled: false,
		},
		router
	)
	.mixin(globalHelpers)
	.component('HeaderBlock', HeaderBlock)
	.component('NewsletterBlock', NewsletterBlock)
	.component('DatePicker', Datepicker)
	.component('CallToActionBlock', CallToActionBlock)
	.component('TwoColumnCenteredBlock', TwoColumnCenteredBlock)
	.component('InstagramFeedBlock', InstagramFeedBlock)
	.component('LargeImageAndTextBlock', LargeImageAndTextBlock)
	.component('TwoColumnTwoImagesBlock', TwoColumnTwoImagesBlock)
	.component('TwoColumnVideoBlock', TwoColumnVideoBlock)
	.component('ImageRowBlock', ImageRowBlock)
	.component('LargeTextBlock', LargeTextBlock)
	.component('FaqsBlock', FaqsBlock)
	.component('MealsBlock', MealsBlock)
	.component('MenuSwapsBlock', MenuSwapsBlock)
	.component('UnsubscribeBlock', UnsubscribeBlock)
	.component('CelebrationBlock', CelebrationBlock)
	.component('MoodBoardBlock', MoodBoardBlock)
	.component('AnchorNavigationBlock', AnchorNavigationBlock)
	.component('ParagraphGridBlock', ParagraphGridBlock)
	.component('FreeTextBlock', FreeTextBlock)
	.component('ParallaxImageGridBlock', ParallaxImageGridBlock)
	.component('TwoColumnFourImagesBlock', TwoColumnFourImagesBlock)
	.component('ContactFormBlock', ContactFormBlock)
	.component('NewsletterFormBlock', NewsletterFormBlock)
	.component('BrasserieListBlock', BrasserieListBlock)
	.component('BrasserieDropdownBlock', BrasserieDropdownBlock)
	.component('SocialCompetitionBlock', SocialCompetitionBlock)
	.component('CompetitionBlock', CompetitionBlock)
	.component('ExponeaAttributeBlock', ExponeaAttributeBlock)
	.mount('#app');

// document.getElementById('header-chat-icon-container').addEventListener('click', function () {
//   document.getElementById('fbots-wdgt').classList.toggle('open')
//   // window.fcWidget.open(); window.fcWidget.show()
//   document.getElementById('header-chat-icon-container').classList.toggle('chat-closed')
//   document.getElementById('header-chat-icon-container').classList.toggle('chat-open')
// })
