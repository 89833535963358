<template>
	<transition>
		<div
			v-if="visible && enabled == 'true'"
			class="splash-screen w-full h-full fixed top-0 left-0 bg-brand"
		>
			<video
				loading="lazy"
				width="221"
				autoplay
				loop
				muted
				playsinline
				class="absolute absolute-center"
			>
				<source src="@/assets/loading.webm" type="video/webm" />
				<source src="@/assets/loading.mp4" type="video/mp4" />
			</video>
			<h2 class="text-cream text-center">{{ text }}</h2>
			<img
				loading="lazy"
				alt="Image"
				src="@/assets/cote-logo.svg"
				class="absolute absolute-center-x top-2/3 w-32"
			/>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'SplashScreen',
	props: {
		loaded: {
			type: Boolean,
			default: null,
		},
		duration: {
			type: Number,
			default: 0,
		},
		mode: {
			type: String,
			default: 'content',
		},
		text: {
			type: String,
			default: '',
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			enabled: process.env.VUE_APP_SPLASH_ENABLED,
			timeoutVisible: true,
		};
	},
	computed: {
		visible() {
			return this.mode === 'content'
				? !this.loaded
				: this.mode === 'duration'
				? this.timeoutVisible
				: this.show;
		},
	},
	mounted() {
		if (this.mode === 'duration') {
			setTimeout(() => {
				this.timeoutVisible = false;
			}, this.duration);
		}
	},
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.splash-screen {
	z-index: 1000;
}
</style>
